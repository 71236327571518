var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("headbox", {
        attrs: { title: "回收标准", isbackPath: true, path: "person" }
      }),
      !_vm.loading
        ? _c("img", {
            attrs: {
              src: require("@/assets/images/recover_short.png"),
              alt: ""
            }
          })
        : _vm._e(),
      _vm._m(0),
      _vm.loading
        ? _c(
            "center",
            { attrs: { id: "loadingbox" } },
            [_c("van-loading", { attrs: { size: "70px" } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "main_info" }, [
      _c("p", [_vm._v("客服热线：15861610892")]),
      _c("br"),
      _c("p", [_vm._v("业务咨询：15861610892（汤清峰）")]),
      _c("br"),
      _c("p", [_vm._v("地址：江苏省无锡市江阴市雅盛嘉园东区89号")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }